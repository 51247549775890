import React, { useEffect } from "react"
import { Hubspot } from "../../helpers/Hubspot"
import { Routes, ExternalRoutes } from "../../data/routes"
import CTA from "../../components/CTA"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Contact",
  description: "Contact Yup",
  header: "Contact Yup",
  headerImage: "contact-header.jpeg",
  headerImageAltText: "Blue background with math symbols",

  // Contact form
  formHeader: "We'd love to hear from you",
  formText: "Send us an email and someone from the team will respond soon.",
  formImage: "contact-cover.jpeg",
  formImageAltText: "Parent and child reading on smartphone",

  // Support links section
  supportHeader: "We're here to help",
  supportOptions: [
    {
      title: "Need help using Yup?",
      link: ExternalRoutes.helpCenter,
      text: "Answers for existing Yup users.",
      icon: "icon-help-center.svg",
      action: "Help Center",
    },
    {
      title: "Need support?",
      link: "tel:+18444773632",
      text: "Give us a call Monday- Friday, 9am – 6pm Pacific.",
      icon: "icon-support.svg",
      action: "(844) 477-3632",
    },
    {
      title: "Want to use Yup at your school?",
      link: "mailto:danielle.rispoli@prenda.co",
      text: "Send us an email to learn more about using Yup with your students.",
      icon: "icon-exchange.svg",
      action: "danielle.rispoli@prenda.co",
    },
  ],

  // CTA section
  ctaTitle: "Do you have any questions?",
  ctaActions: [
    {
      link: Routes.faq,
      text: "FAQ for schools",
    },
    {
      link: Routes.familyFaq,
      text: "FAQ for families",
    },
  ],
}

function Header() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/header#component-17d3a4a8538b7d528d954a5db2874da8
  return (
    <div className="relative">
      <div className="absolute inset-0">
        <Img
          className="w-full h-full"
          src={Content.headerImage}
          alt={Content.headerImageAltText}
          cover={true}
        />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          {Content.header}
        </h1>
      </div>
    </div>
  )
}

function ContactForm() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/contact-sections#component-6bd457a8f982faa1eca40d712ab7278b
  useEffect(() => {
    Hubspot.load("#hubspot-form")
  }, [])
  return (
    <div className="relative">
      <div className="lg:absolute lg:inset-0">
        <div className="hidden lg:block absolute inset-y-0 right-0 w-1/2">
          <Img
            className="h-56 w-full lg:absolute lg:h-full"
            src={Content.formImage}
            alt={Content.formImageAltText}
            cover={true}
          />
        </div>
      </div>
      <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {Content.formHeader}
            </h2>
            <p className="my-4 text-lg text-gray-500 sm:mt-3">
              {Content.formText}
            </p>
            <div id="hubspot-form" />
          </div>
        </div>
      </div>
    </div>
  )
}

function SupportSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/page-examples/contact-pages#component-4b8595b2ed358f486262c6febaeb5ea4

  return (
    <div className="bg-secondary-50">
      <div className="max-w-md mx-auto px-4 py-16 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-4xl mb-8 font-extrabold tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
          {Content.supportHeader}
        </h2>
        {/* Cards */}
        <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-3 lg:gap-y-0">
          {Content.supportOptions.map(option => (
            <div key={option.title} className="px-4">
              <div className="bg-white rounded-lg shadow-lg p-4 sm:p-4 ring-1 ring-gray-900 ring-opacity-5 flex flex-col h-full">
                <div className="flex-1 relative">
                  <Img src={option.icon} />
                  <h3 className="text-xl font-medium text-blue-gray-900">
                    {option.title}
                  </h3>
                  <p className="mt-4 text-base text-blue-gray-500">
                    {option.text}
                  </p>
                </div>
                <div className="bg-blue-gray-50 mt-4 rounded-bl-2xl rounded-br-2xl">
                  <a
                    href={option.link}
                    target="_blank"
                    className="text-base font-medium text-blue hover:text-blue"
                  >
                    {option.action}
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Contact = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.contact}
      />
      <Header />
      <ContactForm />
      <SupportSection />
      <CTA title={Content.ctaTitle} actions={Content.ctaActions} />
    </Layout>
  )
}

export default Contact
